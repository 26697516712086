<template>
    <section>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex mb-3">
                <div class="table-box p-3">
                    <div class="border-bottom pb-3">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <button v-if="data.permission.canCreate"
                                        class="btn btn-primary" @click="openModal('showModal')">
                                    Create New
                                </button>
                            </div>
                        </div>
                    </div>

                    <data-table id="example-data-table"
                        :options="options"
                        :data-set="data.dataList"
                        :page-size="data.pageSize"
                        @change-action="changeAction"
                        @change-status="changeStatus"
                        @pagination="pagination"
                        @sorting="sorting"
                        @limit-change="limitChange"/>

                </div>
            </div>
        </div>
    </section>

    <!-- Modal -->
    <div class="modal fade" id="showModal" tabindex="-1" aria-labelledby="addTemplateCatModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Template Category</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <Form ref="resetForm" @submit="formAction({url: data.setURL, modalId: 'showModal'}, data.inputFormData)"
                      v-slot="{ errors }" :validation-schema="schema">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12 mb-3">
                                <label class="form-control-label">Name</label>
                                <Field type="text" class="form-control" v-model="data.inputFormData.name"
                                       :class="{'border-danger': errors.name}" name="name"/>
                                <span class="text-danger" v-if="errors.name">{{ errors.name }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary">Submit</button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
    <!-- /Modal -->

</template>

<script>
    import DataTable from "@/common/components/datatable";
    import HelperFunction from "@/common/helpers";
    import {useStore} from "vuex";
    import {onMounted} from "vue";
    import {Field, Form} from "vee-validate";
    import * as Yup from "yup";
    import {useRoute} from "vue-router";

    export default {
        name: "TemplateCategory",
        components: {
          DataTable, Field, Form
        },
        setup() {
            const { data, openModal, getAllData, editFormData, deleteListData, storeFormData, resetForm, permissionResolver} = HelperFunction();
            const store = useStore()
            const route = useRoute();
            const company = store.getters.getSelectedBoard
            let setParams = {
                company_id: company.id
            };

            data.setURL = vueConfig.Setup.TempCategoryEndPoint
            onMounted(async () => {
                await getAllData(data.setURL, setParams)
            })

            /** watch **/
            permissionResolver(route.path, true);
            /** watch end **/

            // SET DATATABLE PROPS
            let options = {
                name: 'Template Category List',
                columns: [
                    {
                        title: 'Name',
                        type: 'text',
                        key: 'name',
                        sort: true
                    },
                    {
                        title: 'Status',
                        type: 'radio',
                        key: 'status',
                        modifier: (value, row) => {
                            return value === 'Active' ? 1: 0;
                        },
                        modifier2: (value, row) => {
                            return !data.permission.status;
                        }
                    },
                ],
                showAction: true,
                action: [
                    {
                        title: 'Edit',
                        icon: 'edit',
                        type: 'edit',
                        show: data.permission.canEdit
                    },
                    {
                        title: 'Delete',
                        icon: 'trash-alt',
                        type: 'delete',
                        show: data.permission.canDelete
                    }
                ]
            }
            async function changeAction(action, row) {
                if (action.type === 'edit') {
                    await editFormData({url: `${data.setURL}/${row.id}`, modalId: 'showModal'}, false)
                }
                if (action.type === 'delete') {
                    deleteListData(`${data.setURL}/${row.id}`, setParams)
                }
            }
            const changeStatus = (column, row) => {
                if (row.id) {
                    row._method = 'PUT'
                }
                storeFormData({url: `${vueConfig.Setup.TempCatChangeStatusEndPoint}/${row.id}`}, row, false, setParams,true)
            }
            const pagination = (page) => {
                setParams.page = page
                getAllData(data.setURL, setParams)
            }
            const limitChange = (limit) => {
                setParams.limit = limit
                delete setParams.page
                getAllData(data.setURL, setParams);
            }
            const sorting = (column) => {
                setParams.column = column.key
                setParams.sort = setParams.sort == 'asc' ? 'desc' : 'asc'
                getAllData(data.setURL, setParams);
            }
            function formAction(object, inputData) {
                inputData.company_id = company.id == 0 ? null : company.id
                if (inputData.id) {
                    inputData._method = 'PUT'
                    object.url = `${object.url}/${inputData.id}`
                }
                storeFormData(object, inputData, false, setParams,true)
            }

            //VALIDATION
            const schema = Yup.object().shape({
                name: Yup.string().required()
            });

            return {
                openModal, options, limitChange, changeStatus,pagination,
                changeAction, formAction, data, schema, sorting, resetForm
            }
        }
    }
</script>

<style scoped>

</style>
